import React from 'react';
import css from './LandingPage.module.css';


import Actu from '../../assets/media/actu.jpg'
import Bfm from '../../assets/media/bfmimmo.jpg'
import France from '../../assets/media/france2.png'
import Lemonde from '../../assets/media/lemonde.png'
import Leparisien from '../../assets/media/leparisien.jpg'
import Lyon1ere from '../../assets/media/lyon1ere.png'
import M6info from '../../assets/media/m6info.png'
import Monjardin from '../../assets/media/monjardin_mamaison.png'
import OuestFrance from '../../assets/media/ouestfrance.png'
import Sortiraparis from '../../assets/media/sortiraparis.png'
import Tf1 from '../../assets/media/tf1.jpg'
import Voltage from '../../assets/media/voltage.jpg'




const Media = () => {
  return (
    <div className={css.mediaContainer}>

      <a target="_blank" href="https://www.tf1info.fr/conso/videdo-louer-son-jardin-facon-airbnb-un-nouveau-business-2194551.html"><img src={Tf1} alt='Tf1' height="35px" width="fit-content"/></a>
      <a target="_blank" href="https://fb.watch/bt0Lp5wdIn/"><img src={France} alt='France' height="35px" width="fit-content"/></a>
      <a target="_blank" href="https://www.lemonde.fr/economie/article/2021/08/18/la-location-de-jardins-entre-particuliers-connait-un-vif-essor_6091690_3234.html"><img src={Lemonde} alt='Lemonde' height="35px" width="fit-content"/></a>
      <a target="_blank" href="https://www.ouest-france.fr/leditiondusoir/2021-09-07/louer-un-jardin-la-nouvelle-tendance-qui-cartonne-depuis-la-pandemie-de-covid-19-3e7e2f43-97f2-4189-a8d0-ae77d3239355"><img src={OuestFrance} alt='OuestFrance' height="35px" width="fit-content"/></a>
      <a target="_blank" href="https://www.sortiraparis.com/arts-culture/balades/articles/139420-jardins-prives-com-le-airbnb-du-jardin"><img src={Sortiraparis} alt='Sortiraparis' height="35px" width="fit-content"/></a>
      <a target="_blank" href="https://www.leparisien.fr/seine-et-marne-77/depuis-le-deconfinement-la-location-de-jardins-prives-explose-en-ile-de-france-18-08-2020-8369718.php"><img src={Leparisien} alt='Leparisien' height="35px" width="fit-content"/></a>
      <a target="_blank" href="https://actu.fr/lifestyle/la-location-potager-piscine-de-jardin-un-barbecue-cest-derniere-tendance_17380682.html"><img src={Actu} alt='Actu' height="35px" width="fit-content"/></a>
      <a target="_blank" href="https://www.lyonpremiere.fr/mes-infos/profiter-dun-jardin-sans-en-avoir-cest-possible-avec-jardins-prives/"><img src={Lyon1ere} alt='Lyon1ere' height="35px" width="fit-content"/></a>
      <a target="_blank" href="https://www.bfmtv.com/immobilier/la-location-de-jardins-a-le-vent-en-poupe-entre-particuliers_AN-202108090353.html"><img src={Bfm} alt='Bfm' height="35px" width="fit-content"/></a>
      <a target="_blank" href="https://www.voltage.fr/news/louer-un-jardin-pour-une-journee-c-est-possible-53883"><img src={Voltage} alt='Voltage' height="35px" width="fit-content"/></a>
      <a target="_blank" href="https://fr-fr.facebook.com/M6info/videos/un-site-internet-pour-partager-son-jardin/330369151490023/"><img src={M6info} alt='M6info' height="35px" width="fit-content"/></a>
      <a target="_blank" href="https://monjardinmamaison.maison-travaux.fr/mon-jardin-ma-maison/actualites-jardin/louer-jardin-tiny-house-jardins-prives-243037.html"><img src={Monjardin} alt='Monjardin' height="35px" width="fit-content"/></a>

    </div>
  )
}

export default Media;
