import React from 'react';
import { Slide } from 'react-slideshow-image';
import '../../styles/slideshow.css'
import css from './LandingPage.module.css';

import  StarYellow  from '../../assets/icons/006_Profil/star_yellow.svg'

const MySlide = () => {
  return (
    <Slide easing="ease">
        <div className={css.eachslide}>
        <div className={css.slideShowWrapper}>
            <p className={css.slideText}>
            <img src={StarYellow} style={{height: 20}} />
            <img src={StarYellow} style={{height: 20}} />
            <img src={StarYellow} style={{height: 20}} />
            <img src={StarYellow} style={{height: 20}} />
            <img src={StarYellow} style={{height: 20}} /><br /><br />
            <span>J’ai loué mon jardin à plusieurs reprises et c’est vraiment pratique quand on veut compléter ses
                revenus.  Plateforme sérieuse et réactive, je recommande.</span>
            <br/><br/>
            <b>Germain, Propriétaire </b>
            </p>
            <p className={css.slideText}>
            <img src={StarYellow} style={{height: 20}} />
            <img src={StarYellow} style={{height: 20}} />
            <img src={StarYellow} style={{height: 20}} />
            <img src={StarYellow} style={{height: 20}} />
            <img src={StarYellow} style={{height: 20}} /><br /><br />
            <span>J'ai loué mon jardin et tout s'est très bien passé. Les locataires étaient contents et heureux de leur journée. Quelle bonne idée ! </span>
            <br/><br/>
            <b>Pierre, Propriétaire </b>
            </p>
        </div>
    </div>
    <div className={css.eachslide}>
        <div className={css.slideShowWrapper}>
            <p className={css.slideText}>
            <img src={StarYellow} style={{height: 20}} />
            <img src={StarYellow} style={{height: 20}} />
            <img src={StarYellow} style={{height: 20}} />
            <img src={StarYellow} style={{height: 20}} />
            <img src={StarYellow} style={{height: 20}} /><br /><br />
            <span>Grâce à la plateforme, j’ai trouvé une personne qui m’aide à entretenir mon jardin quand je n’ai pas le temps de m’en occuper. Une bonne solution.</span>
            <br/><br/>
            <b>Sonia, Propriétaire </b>
            </p>
            <p className={css.slideText}>
            <img src={StarYellow} style={{height: 20}} />
            <img src={StarYellow} style={{height: 20}} />
            <img src={StarYellow} style={{height: 20}} />
            <img src={StarYellow} style={{height: 20}} />
            <img src={StarYellow} style={{height: 20}} /><br /><br />
            <span>Je suis très satisfaite des services proposés sur le site qui m’aident à rencontrer de nouvelles personnes pour entretenir gratuitement mon potager. </span>
            <br/><br/>
            <b>Danielle, Propriétaire </b>
            </p>
        </div>
    </div>

    <div className={css.eachslide}>
        <div className={css.slideShowWrapper}>
            <p className={css.slideText}>
              <img src={StarYellow} style={{height: 20}} />
              <img src={StarYellow} style={{height: 20}} />
              <img src={StarYellow} style={{height: 20}} />
              <img src={StarYellow} style={{height: 20}} />
              <img src={StarYellow} style={{height: 20}} /><br /><br />
              <span>J’ai loué un jardin pour les 20 ans de mon fils, tout était parfait et on a passé une journée formidable dans un très beau jardin près de Paris. Top, merci beaucoup. </span>
              <br/><br/>
              <b>Diego, Hôte </b>
            </p>
            <p className={css.slideText}>
            <img src={StarYellow} style={{height: 20}} />
            <img src={StarYellow} style={{height: 20}} />
            <img src={StarYellow} style={{height: 20}} />
            <img src={StarYellow} style={{height: 20}} />
            <img src={StarYellow} style={{height: 20}} /><br /><br />
            <span>Très bonne expérience ! J'ai loué un jardin ce week-end avec mes amis et nous avons passé un super moment au bord de la piscine. Vraiment très sympathique ! Je recommande le site !</span>
            <br/><br/>
            <b>Christophe, Hôte  </b>
            </p>
        </div>
    </div>

    <div className={css.eachslide}>
        <div className={css.slideShowWrapper}>
            <p className={css.slideText}>
            <img src={StarYellow} style={{height: 20}} />
            <img src={StarYellow} style={{height: 20}} />
            <img src={StarYellow} style={{height: 20}} />
            <img src={StarYellow} style={{height: 20}} />
            <img src={StarYellow} style={{height: 20}} /><br /><br />
                <span>Je cultive mon potager depuis 6 mois grâce à Jardins Privés. Un service de partage entièrement gratuit c’est rare, merci.</span>
                <br/><br/>
                <b>Paul, Hôte</b>
            </p>
            <p className={css.slideText}>
            <img src={StarYellow} style={{height: 20}} />
            <img src={StarYellow} style={{height: 20}} />
            <img src={StarYellow} style={{height: 20}} />
            <img src={StarYellow} style={{height: 20}} />
            <img src={StarYellow} style={{height: 20}} /><br /><br />
                <span>Avec mes collocs nous nous sommes cotisés pour louer un jardin et on a passé une super journée. Merci beaucoup pour cette initiative vraiment intelligente et agréable !</span>
                <br/><br/>
                <b>Veronique, Hôte </b>
            </p>
        </div>
    </div>
  </Slide>
  )
};

export default MySlide
