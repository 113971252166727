import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import config from '../../config';
import {
  Page,
  SectionHero,
  ListingCard,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedLink,
  IconReviewStar,
  AvatarMedium
} from '../../components';
import { TopbarContainer } from '../../containers';
import { Grid, Paper } from '@material-ui/core'
import MySlide from './Slide'
import Media from './Media'
// Images
import Block1Img from '../../assets/home/photos/block1.jpg'
import Block2Img from '../../assets/home/photos/block2.jpg'
import Block3Img from '../../assets/home/photos/block3.jpg'

import PictoRent from '../../assets/home/icons/rent.svg'
import PictoShare from '../../assets/home/icons/share.svg'
import PictoPotager from '../../assets/home/icons/potager.svg'
import PictoTinyhouse from '../../assets/home/icons/tinyhouse.svg'


import { ReactComponent as RentStamp } from '../../assets/home/illustrations/Location-jardin-01.svg'
import { ReactComponent as ShareStamp } from '../../assets/home/illustrations/potager-01.svg'
import { ReactComponent as TinyhouseStamp } from '../../assets/home/illustrations/tinyhouse-01.svg'


import facebookImage from '../../assets/jardinsPrivesFacebook-1200x630.jpg';
import twitterImage from '../../assets/jardinsPrivesTwitter-600x314.jpg';
import css from './LandingPage.module.css';


export const LandingPageComponent = props => {
  const { history, intl, location, scrollingDisabled = false } = props;

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  // Panel width relative to the viewport
  const panelMediumWidth = 50;
  const panelLargeWidth = 62.5;
  const cardRenderSizes = [
    '(max-width: 767px) 100vw',
    `(max-width: 1023px) ${panelMediumWidth}vw`,
    `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
    `${panelLargeWidth / 3}vw`,
  ].join(', ');

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.heroContainer}>
            <SectionHero className={css.hero} history={history} location={location} />
          </div>
          <div className={css.container}>
            <div className={css.categoryContainer}>
              <div>
                <h1><FormattedMessage id="LandingPage.title1" /></h1>
                <h2>Louer un jardin pour fêter un anniversaire entre amis, partager un jardin pour cultiver ses légumes <br />ou séjourner en pleine nature dans une tinyhouse</h2>
              </div>
              <Grid container spacing={3} justifyContent="center">
                <Grid item xs={6} sm={3}>
                  <NamedLink
                    name="SearchPage"
                    to={{ search: `?pub_category=garden_rent` }}>
                    <div className={css.categoryImg1}>
                      <h3><FormattedMessage id="LandingPage.category1" /></h3>
                    </div>
                  </NamedLink>

                </Grid>
                <Grid item xs={6} sm={3}>
                  <NamedLink
                    name="SearchPage"
                    to={{ search: `?pub_category=garden_share&pub_garden_type=cultivate` }}>
                    <div className={css.categoryImg2}>
                      <h3><FormattedMessage id="LandingPage.category2" /></h3>
                    </div>
                  </NamedLink>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <NamedLink
                    name="SearchPage"
                    to={{ search: `?pub_category=garden_share&pub_garden_type=maintain` }}>
                    <div className={css.categoryImg3}>
                      <h3><FormattedMessage id="LandingPage.category3" /></h3>
                    </div>
                  </NamedLink>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <NamedLink
                    name="SearchPage"
                    to={{ search: `?pub_category=tinyhouse` }}>
                    <div className={css.categoryImg4}>
                      <h3><FormattedMessage id="LandingPage.category4" /></h3>
                    </div>
                  </NamedLink>
                </Grid>
              </Grid>
            </div>

            <div className={css.pictoContainer}>
              <h1><FormattedMessage id="LandingPage.title2" /></h1>
              <Grid container spacing={4} justifyContent="center">
                <Grid item xs={12} sm={6}>
                  <div className={css.picto1}>
                    <img className={css.img} src={PictoRent} alt="rent" />
                    <h3><FormattedMessage id="LandingPage.titlePicto1" /></h3>
                    <p><FormattedMessage id="LandingPage.subTitlePicto1" /></p>
                    <NamedLink
                      name="SearchPage"
                      to={{ search: "?pub_category=garden_rent" }}>
                      <FormattedMessage id="LandingPage.linkPicto1" />
                    </NamedLink>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className={css.picto2}>
                    <img className={css.img} src={PictoShare} alt="rent" />
                    <h3><FormattedMessage id="LandingPage.titlePicto2" /></h3>
                    <p><FormattedMessage id="LandingPage.subTitlePicto2" /></p>
                    <NamedLink
                      name="SearchPage"
                      to={{ search: "?pub_category=garden_share&pub_garden_type=maintain" }}>
                      <FormattedMessage id="LandingPage.linkPicto2" />
                    </NamedLink>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className={css.picto3}>
                    <img className={css.img} src={PictoPotager} alt="rent" />
                    <h3><FormattedMessage id="LandingPage.titlePicto3" /></h3>
                    <p>Entretenir un potager chez un propriétaire et<br />lui offrir une partie de la récolte en échange.</p>
                    <NamedLink
                      name="SearchPage"
                      to={{ search: "?pub_category=garden_share&pub_garden_type=cultivate" }}>
                      <FormattedMessage id="LandingPage.linkPicto3" />
                    </NamedLink>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className={css.picto4}>
                    <img className={css.img} src={PictoTinyhouse} alt="rent" />
                    <h3><FormattedMessage id="LandingPage.titlePicto4" /></h3>
                    <p>Louer sa petite maison écologique en bois à<br />des citadins en quête de verdure.</p>
                    <NamedLink
                      name="SearchPage"
                      to={{ search: "?pub_category=tinyhouse" }}>
                      <FormattedMessage id="LandingPage.linkPicto4" />
                    </NamedLink>
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className={css.blockContainer}>
              <h1><FormattedMessage id="LandingPage.title3" /></h1>

              <div className={css.block}>
                <div className={css.blockImage}>
                  <RentStamp className={css.stampLeft} />
                  <img src={Block1Img} alt="rent" width="100%" />
                </div>
                <div className={css.blockText}>
                  <div>
                    <h2 className={css.blockTitle}><FormattedMessage id="LandingPage.block1Title" /></h2>
                    <p><FormattedMessage id="LandingPage.block1Text" /></p>
                  </div>
                </div>
              </div>
              <br />
              <div className={css.block2}>
                <div className={css.blockText} style={{ marginLeft: 0, marginRight: 10 }}>
                  <div>
                    <h2 className={css.blockTitle}><FormattedMessage id="LandingPage.block2Title" /><br />
                      <FormattedMessage id="LandingPage.block21Title" /></h2>
                    <p>
                      <FormattedMessage id="LandingPage.block2Text" />
                    </p>
                  </div>

                </div>
                <div className={css.blockImage}>
                  <ShareStamp className={css.stampRight} />
                  <img src={Block2Img} alt="rent" width="100%" />
                </div>
              </div>
              <div className={css.block2Mobile}>
                <div className={css.blockImage}>
                  <ShareStamp className={css.stampRight} />
                  <img src={Block2Img} alt="rent" width="100%" />
                </div>
                <div className={css.blockText}>
                  <div>
                    <h2 className={css.blockTitle}><FormattedMessage id="LandingPage.block2Title" /></h2>
                    <p><FormattedMessage id="LandingPage.block2Text" /></p>
                  </div>

                </div>
              </div>
              <br />

              <div className={css.block}>
                <div className={css.blockImage}>
                  <TinyhouseStamp className={css.stampLeft} />
                  <img src={Block3Img} alt="rent" width="100%" />
                </div>
                <div className={css.blockText}>
                  <div>
                    <h2 className={css.blockTitle}><FormattedMessage id="LandingPage.block3Title" /></h2>
                    <p><FormattedMessage id="LandingPage.block3Text" /></p>
                  </div>
                </div>
              </div>


            </div>
            <div className={css.listingsContainer}>
              <h1><FormattedMessage id="LandingPage.title4" /></h1>
              <Grid container spacing={1}>
                {props.listings.length > 0 &&
                  props.listings.map((l, i) => (
                    <Grid item xs={12} sm={4} key={i} className={css.listingCard}>
                      <Paper className={css.paper}>
                        <ListingCard
                          className={css.listingCard}
                          key={l.id.uuid}
                          listing={l}
                          renderSizes={cardRenderSizes}
                        />
                        <div className={css.ListingCardAuthor}>
                          <img src={l.author &&
                            l.author.profileImage &&
                            l.author.profileImage.attributes &&
                            l.author.profileImage.attributes.variants &&
                            l.author.profileImage.attributes.variants.default &&
                            l.author.profileImage.attributes.variants.default.url}
                            height="30"
                          />
                          <NamedLink
                            name="ProfilePage"
                            params={{ id: l.author.id.uuid }}
                          >
                            <p>{l.author.attributes.profile.displayName}</p>
                          </NamedLink>

                        </div>
                      </Paper>

                    </Grid>
                  ))}
              </Grid>
            </div>


            <div className={css.clientContainer}>
              <h1 style={{ marginBottom: 0 }}><FormattedMessage id="LandingPage.title6" /></h1>
              <MySlide />
            </div>

            <div className={css.sponsorContainer}>
              <h1><FormattedMessage id="LandingPage.title7" /></h1>
              <Media />
            </div>


            <div className={css.videoContainer}>
              <div className="youtube_player" videoid="SDlpKCHqAis" width="100%" height="400px" theme="dark" rel="1" controls="0" showinfo="0" autoPlay="0" mute="0" loop="1" loading="0"></div>
            </div>



          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const { bool, object } = PropTypes;

LandingPageComponent.propTypes = {
  // scrollingDisabled: bool.isRequired,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    listingsError,
    inProgress,
    currentPageResultIds
  } = state.LandingPage;
  const pageListings = getListingsById(state, currentPageResultIds);
  return {
    listings: pageListings,
    inProgress,
    listingsError
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  withRouter,
  connect(mapStateToProps),
  injectIntl
)(LandingPageComponent);

export default LandingPage;
